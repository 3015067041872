import React, { useState } from "react"

import styled from "styled-components"
import PageWrapper from "../components/PageWrapper"
import { Helmet } from "react-helmet"

import { Title, Section, Text } from "../components/Core"
import { Container, Row, Col } from "react-bootstrap"
import { t } from "@lingui/macro"
import SEO from "../components/SEO"

const HeroWrapper = styled(Section)`
  background-color: #f4f4f4;
`

const Appointment = ({ location }) => {
  return (
    <>
      <SEO
        pathname={location.pathname}
        title={t`Termin Vereinbaren`}
        description={t`Vereinbaren Sie ein kostenfreien Beratungstermin für die Möglichkeiten von künstlicher Intelligenz. AMAI hilft Ihnen, Künstliche Intelligenz in Ihre Prozesse und Produkte zu integrieren. Auf dem Gebiet des Machine Learning, Deep Learning sind wir Experten.`}
      />
      <PageWrapper>
        <HeroWrapper>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <div className="banner-content">
                  <Title variant="hero">{t`Direkt Termin vereinbaren`}</Title>
                  <Text>
                    {t`In folgendem Formular können Sie direkt einen kostenfreien Video-Telefontermin mit uns vereinbaren. Einfach freien Termin wählen und wir senden Ihnen eine Einladung.`}
                  </Text>
                </div>
              </Col>
            </Row>
          </Container>
        </HeroWrapper>
        <Helmet>
          <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
        </Helmet>
        <div class="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/leonard-plotkin/ai-beratungsgesprach?embed=true"></div>
      </PageWrapper>
    </>
  )
}
export default Appointment
